<template>
  <base-layout title="Loged Out">
    <ion-grid>
      <ion-row class="push-down">
        <ion-col
          size="12"
          size-md="6"
          offset-md="3"
          size-lg="4"
          offset-lg="4"
          
        >
          <h3 class="ion-text-center">You have loged out!</h3>

          <ion-button
            expand="full"
            color="success"
            @click="close"
          >
            Login
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </base-layout>
</template>
<script>
import { IonGrid, IonRow, IonCol, IonButton } from "@ionic/vue";
// import { getPlatforms } from "@ionic/vue";
export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,

    IonButton,
  },
  data() {
    return {
      // platforms: getPlatforms(),
    };
  },

  methods: {
    close(){
      this.$router.replace({ name: "login", params: { reload: true } });
    }
  },
};
</script>
<style scoped>
.push-down {
  margin-top: 30vh;
}

h3 {
  margin-bottom: 2rem;
}
</style>